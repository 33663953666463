import useSWR from 'swr'
import { fetcherGraphQL } from '@/utils/fetch'
import PostCard from '@/components/shared/Post/PostCard'
import { useBreakpoints } from '@/hooks/useBreakpoints'
import { SERIES_TERM_ID } from '@/constants'

function useNewPost(postId: number) {
  const { data, error } = useSWR(
    `
  {
    posts(where: {notIn: ${postId}, orderby: {field: DATE, order: DESC}, categoryNotIn: [518, 515, 521]}, first: 4) {
      nodes {
        title
        slug
        date
        excerpt
        databaseId
        featuredImage {
          node {
            sourceUrl
            srcSet
          }
        }
        categories {
          nodes {
            name
            parentDatabaseId
            slug
          }
        }
      }
    }
  }
  `,
    fetcherGraphQL
  )
  const posts = data?.posts?.nodes.map((post: any) => ({
    ...post,
    // convert date from iso to DD.MM.YYYY
    date: post.date
      .split('T')[0]
      .split('-')
      .reverse()
      .join('.'),
    featuredImage: post.featuredImage.node,
    category:
      post.categories.nodes.length > 0
        ? {
            name: post.categories.nodes[0].name,
            slug: post.categories.nodes[0].slug,
            type:
              post.categories.nodes[0].parentDatabaseId === SERIES_TERM_ID
                ? 'series'
                : 'topic',
          }
        : [],
  }))
  return {
    posts,
    isLoading: !error && !data,
    error,
  }
}

const NewPosts = ({ postId }: { postId: number }) => {
  const { posts } = useNewPost(postId)
  const { isDesktop, isMobile } = useBreakpoints()
  return (
    <>
      {isMobile && <hr style={{ marginLeft: '-20px', marginRight: '-20px' }} />}
      <div className="flex flex-col pt-8 space-y-8 tablet:base-container">
        <h4 className="section-heading">Bài viết mới</h4>
        <div className="flex flex-col space-x-0 space-y-8 desktop:flex-row desktop:space-x-4 desktop:space-y-0">
          {posts &&
            posts.map((post: any, index: number) => (
              <PostCard
                key={index}
                className="flex space-x-3 w-full h-auto desktop:flex-col desktop:space-y-4 desktop:space-x-0"
                image={post.featuredImage}
                {...post}
              >
                <>
                  <PostCard.Image
                    isGatsbyImage={false}
                    wrapperClassName="w-full"
                    className="object-cover bg-center bg-no-repeat bg-cover min-h-full min-w-full aspect-square tablet:aspect-[1.5] desktop:aspect-square max-w-[120px] tablet:min-w-full"
                  />
                  <div className="flex flex-col w-[60%] flex-shrink-0 justify-between space-y-2 desktop:w-full desktop:flex-grow">
                    {!isDesktop && <PostCard.Date />}
                    <PostCard.Title heading="heading-6 desktop:subheading-5" />
                    {!isMobile && <PostCard.Excerpt />}
                    <span>
                      <PostCard.Category />
                    </span>
                  </div>
                </>
              </PostCard>
            ))}
        </div>
      </div>
    </>
  )
}

export default NewPosts
